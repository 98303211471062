import { defineStore } from "pinia";

type SupportStep =
  | "INITIAL"
  | "TYPE_CHOICE"
  | "CHAT"
  | "CHAT_SENT"
  | "REQUEST_ISSUE"
  | "REQUEST_SENT"
  | "REQUEST_ERROR";

type FormState = {
  topic: string;
  message: string;
};

export const useSupportStore = defineStore("support", () => {
  const { trackOtherEvent } = useAnalytics();

  const step = ref<SupportStep>("INITIAL");

  const formState = ref<FormState>({
    topic: "",
    message: "",
  });

  const setFormState = (payload: FormState) => {
    formState.value = payload;
  };

  const clearFormState = () => {
    formState.value = {
      topic: "",
      message: "",
    };
  };

  const setSupportStep = (supportStep: SupportStep) => {
    trackOtherEvent(`support-${supportStep}`);
    step.value = supportStep;
  };

  const goToInitialStep = () => {
    setSupportStep("INITIAL");
  };

  const goToTypeChoiceStep = () => {
    setSupportStep("TYPE_CHOICE");
  };

  const goToChatStep = () => {
    setSupportStep("CHAT");
  };

  const goToChatSentStep = () => {
    setSupportStep("CHAT_SENT");
  };

  const goToRequestIssueStep = () => {
    setSupportStep("REQUEST_ISSUE");
  };

  const goToRequestSentStep = () => {
    setSupportStep("REQUEST_SENT");
  };

  const goToRequestErrorStep = () => {
    setSupportStep("REQUEST_ERROR");
  };

  const openSupport = () => {
    goToRequestIssueStep();
  };

  return {
    step,
    formState,
    setFormState,
    clearFormState,
    goToInitialStep,
    goToTypeChoiceStep,
    goToChatStep,
    goToChatSentStep,
    goToRequestIssueStep,
    goToRequestSentStep,
    goToRequestErrorStep,
    openSupport,
  };
});
